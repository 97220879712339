define("discourse/plugins/discourse-ai/discourse/templates/connectors/composer-fields/persona-selector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="gpt-persona">
    <DropdownSelectBox
      @value={{this.value}}
      @content={{this.botOptions}}
      @options={{hash icon="robot"}}
    />
  </div>
  */
  {
    "id": "UslvXgsD",
    "block": "[[[10,0],[14,0,\"gpt-persona\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@value\",\"@content\",\"@options\"],[[30,0,[\"value\"]],[30,0,[\"botOptions\"]],[28,[37,1],null,[[\"icon\"],[\"robot\"]]]]],null],[1,\"\\n\"],[13]],[],false,[\"dropdown-select-box\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-ai/discourse/templates/connectors/composer-fields/persona-selector.hbs",
    "isStrictMode": false
  });
});