define("discourse/plugins/discourse-ai/discourse/connectors/full-page-search-below-search-header/semantic-search", ["exports", "@glimmer/component", "@ember/object", "I18n", "@glimmer/tracking", "discourse/lib/ajax", "discourse/lib/search", "discourse-common/lib/debounce", "@ember/service", "discourse-common/utils/decorators", "discourse/controllers/full-page-search"], function (_exports, _component, _object, _I18n, _tracking, _ajax, _search, _debounce, _service, _decorators, _fullPageSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let _class = _exports.default = (_dec = (0, _object.computed)("args.outletArgs.search"), _dec2 = (0, _object.computed)("args.outletArgs.type", "searchTerm"), _dec3 = (0, _object.computed)("results"), (_class2 = class _class2 extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "appEvents", _descriptor, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
      _initializerDefineProperty(this, "searching", _descriptor3, this);
      _initializerDefineProperty(this, "collapsedResults", _descriptor4, this);
      _initializerDefineProperty(this, "results", _descriptor5, this);
    }
    static shouldRender(_args, _ref) {
      let {
        siteSettings
      } = _ref;
      return siteSettings.ai_embeddings_semantic_search_enabled;
    }
    get searchTerm() {
      return this.args.outletArgs.search;
    }
    get searchEnabled() {
      return this.args.outletArgs.type === _fullPageSearch.SEARCH_TYPE_DEFAULT && (0, _search.isValidSearchTerm)(this.searchTerm, this.siteSettings);
    }
    get collapsedResultsTitle() {
      return _I18n.default.t("discourse_ai.embeddings.semantic_search_results.toggle", {
        count: this.results.length
      });
    }
    setup() {
      this.appEvents.on("full-page-search:trigger-search", this, "debouncedSearch");
    }
    teardown() {
      this.appEvents.off("full-page-search:trigger-search", this, "debouncedSearch");
    }
    performHyDESearch() {
      if (!this.searchEnabled) {
        return;
      }
      this.searching = true;
      this.collapsedResults = true;
      this.results = [];
      (0, _ajax.ajax)("/discourse-ai/embeddings/semantic-search", {
        data: {
          q: this.searchTerm
        }
      }).then(async results => {
        const model = (await (0, _search.translateResults)(results)) || {};
        this.results = model.posts;
      }).finally(() => this.searching = false);
    }
    debouncedSearch() {
      (0, _debounce.default)(this, this.performHyDESearch, 500);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "appEvents", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "searching", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "collapsedResults", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "results", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "searchTerm", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "searchTerm"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "searchEnabled", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "searchEnabled"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "collapsedResultsTitle", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "collapsedResultsTitle"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "setup"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "teardown", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "teardown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "performHyDESearch", [_decorators.bind], Object.getOwnPropertyDescriptor(_class2.prototype, "performHyDESearch"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "debouncedSearch", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "debouncedSearch"), _class2.prototype)), _class2));
});