define("discourse/plugins/discourse-ai/discourse/connectors/composer-fields/persona-selector", ["exports", "@glimmer/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  function isBotMessage(composer, currentUser) {
    if (composer && composer.targetRecipients && currentUser.ai_enabled_chat_bots) {
      const reciepients = composer.targetRecipients.split(",");
      return currentUser.ai_enabled_chat_bots.any(bot => reciepients.any(username => username === bot.username));
    }
    return false;
  }
  let BotSelector = _exports.default = (_class = class BotSelector extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
    }
    static shouldRender(args, container) {
      return container?.currentUser?.ai_enabled_personas && isBotMessage(args.model, container.currentUser);
    }
    get composer() {
      return this.args?.outletArgs?.model;
    }
    get botOptions() {
      if (this.currentUser.ai_enabled_personas) {
        return this.currentUser.ai_enabled_personas.map(persona => {
          return {
            id: persona.name,
            name: persona.name,
            description: persona.description
          };
        });
      }
    }
    get value() {
      return this._value || this.botOptions[0].id;
    }
    set value(val) {
      this._value = val;
      this.composer.metaData = {
        ai_persona: val
      };
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});